import React from 'react';
import ReactDOM from 'react-dom/client';
import 'utils/i18n';
import { BrowserRouter } from 'react-router-dom';
import { EventType, EventMessage, AuthenticationResult } from '@azure/msal-browser';

import { ai, getAppInsights } from 'app/utils/telemetry';
import App from 'app/components/App';
import { msalInstance } from 'auth';
import cookies from 'js-cookie';

import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';

import './index.scss';

ai.initialize();
// excerpt of the config section of the JavaScript SDK snippet with correlation
// between client-side AJAX and server requests enabled.

msalInstance.addEventCallback((event: EventMessage) => {
  if (event.eventType === EventType.HANDLE_REDIRECT_END) {
    const activeAccount = msalInstance.getActiveAccount();
    const appInsights = getAppInsights();
    if (activeAccount && appInsights) {
      appInsights.setAuthenticatedUserContext(activeAccount.username, undefined, true);
    }
  }
  if (event.eventType === EventType.LOGOUT_SUCCESS) {
    const sld = window.location.host.match('[^.]+.[^.]+$');
    cookies.remove('_oauth2_proxy', { domain: `.${sld}`});

    const appInsights = getAppInsights();
    if (appInsights) {
      appInsights.clearAuthenticatedUserContext();
    }
  }
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
    const payload = event.payload as AuthenticationResult;
    const { account } = payload;
    msalInstance.setActiveAccount(account);
  }
});

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <App pca={msalInstance} />
    </BrowserRouter>
  </React.StrictMode>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
