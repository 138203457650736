/** 
 * All data-testid used for e2eTests.
 */
export const testIds = {
  // ----- Overview -----
  OVERVIEW_TITLE: 'overview-title',
  // ----- TreeView -----
  TREEVIEW_ITEM: 'treeview-item',
  TREEVIEW_ITEM_TEXT: 'treeview-item-text',
  TREEVIEW_ITEM_EXPAND_ARROW: 'treeview-item-expand-arrow',
  TREEVIEW_SEARCH_BAR: 'treeview-search-bar',
  TREEVIEW_SUGGESTIONS_BOX: 'treeview-suggestions-box',
  TREEVIEW_SUGGESTION_ITEM: 'treeview-suggestion-item',
  // ----- Timeline -----
  TIMELINE_TITLE: 'timeline-title',
  TIMELINE_DATA_POINT: 'timeline-data-point',
  TIMELINE_TOOLTIP: 'timeline-tooltip',
  TIMELINE_HEADER_STATUS: 'timeline-header-status',
  // ----- Icons -----
  ICON: 'icon',
  // ----- Top-level Timefilter -----
  TOP_LEVEL_TIMEFILTER_6M: 'top_level_timefilter_6m',
  TOP_LEVEL_TIMEFILTER_1Y: 'top_level_timefilter_1y',
  TOP_LEVEL_TIMEFILTER_2Y: 'top_level_timefilter_2y',
  TOP_LEVEL_TIMEFILTER_5Y: 'top_level_timefilter_5y',
  TOP_LEVEL_TIMEFILTER_10Y: 'top_level_timefilter_10y',
  TOP_LEVEL_TIMEFILTER_FULL: 'top_level_timefilter_full',
  TOP_LEVEL_TIMEFILTER_CUSTOM: 'top_level_timefilter_custom',
  // ----- CHARTS -----
  RUL_HISTOGRAM: 'rul-histogram',
  RUL_HISTOGRAM_BAR: 'rul-histogram-bar',
  SOH_HISTOGRAM: 'soh-histogram',
  SOH_HISTOGRAM_BAR: 'soh-histogram-bar',
  // ----- TABLE -----
  RUL_TABLE: 'rul-table',
  SOH_TABLE: 'soh-table',
  TABLE_HEADER_COLUMN: 'table-header-column',
  TABLE_ROW: 'table-row',
  TABLE_CELL: 'table-cell',
  // ----- SOS Status -----
  SOS_STATUS_CONTAINER: 'sos_status_container',
  // ----- Cards -----
  RUL_CARD: 'rul_card',
}
