import { testIds } from 'testIds';

interface IProps {
  fill?: string;
  width?: string;
  height?: string;
}

function Multiply({ fill, width, height }: IProps) {
  return (
    <svg data-testid={`${testIds.ICON}-multiply`} width={width} height={height} viewBox={'0 0 11 11'} fill={'none'} xmlns={'http://www.w3.org/2000/svg'}>
      <path
        d={
          "M2.2 1.19l3.3 3.3L8.8 1.2a.67.67 0 0 1 .5-.2a.75.75 0 0 1 .7.7a.66.66 0 0 1-.2.48L6.49 5.5L9.8 8.82c.13.126.202.3.2.48a.75.75 0 0 1-.7.7a.67.67 0 0 1-.5-.2L5.5 6.51L2.21 9.8a.67.67 0 0 1-.5.2a.75.75 0 0 1-.71-.71a.66.66 0 0 1 .2-.48L4.51 5.5L1.19 2.18A.66.66 0 0 1 1 1.7a.75.75 0 0 1 .7-.7a.67.67 0 0 1 .5.19z"
        }
        fill={fill}
      />
    </svg>
  );
}

Multiply.defaultProps = {
  fill: '#fff',
  width: '36',
  height: '36',
};

export default Multiply;
